<template>
  <div>
    <v-card>
      <v-app-bar dark color="primary">
        <v-icon large class="mr-3">person_outline</v-icon>
        <v-toolbar-title>User Profiling</v-toolbar-title>
      </v-app-bar>

      <v-card-text>
        <v-autocomplete
          prepend-icon="list"
          label="Vendor Code"
          :items="projectCodes"
          :item-text="(item) => `${item.name} - ${item.projectCode}`"
          item-value="projectCode"
          v-model="dataToSend.projectCodes"
          multiple
          v-validate="'required'"
          data-vv-name="vendor code"
          :error-messages="errors.collect('vendor code')"
        >
          <v-list-item slot="prepend-item" ripple @click="selectAll">
            <v-list-item-action>
              <v-icon :color="dataToSend.projectCodes.length > 0 ? 'primary darken-4' : ''" > {{ icon }} </v-icon>
            </v-list-item-action>
            <v-list-item-title>
              <div v-if="!allSelected">Select All</div>
              <div v-else>Unselect All</div>
            </v-list-item-title>
          </v-list-item>
          <v-divider slot="prepend-item" class="mt-2"></v-divider>
          <v-divider slot="append-item" class="mb-2"></v-divider>
        </v-autocomplete>

        <v-row no-gutters>
          <v-col>
            <v-menu
              ref="startDateMenu"
              v-model="startDateMenu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dataToSend.startDate"
                  label="From"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                color="primary"
                no-title
                scrollable
                v-model="dataToSend.startDate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.startDateMenu.save(Date(startDate))"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <v-menu
              ref="endDateMenu"
              v-model="endDateMenu"
              :close-on-content-click="false"
              :return-value.sync="endDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="To"
                  v-model="dataToSend.endDate"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                no-title
                color="primary"
                scrollable
                v-model="dataToSend.endDate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.endDateMenu.save(Date(endDate))"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row no-gutters class="justify-center align-center">
          <v-btn class="ma-2" color="primary" @click="fetchData()">Load</v-btn>
          <v-btn class="ma-2" @click="goToUsers()" color="primary">View Users</v-btn>
          <v-btn class="ma-2" @click="clearFilter()" color="primary">Clear Filter</v-btn>
          <v-btn class="ma-2" v-if="checkUserType('ADMIN')" @click="getOverallStatus()" color="primary">Get Overall Status</v-btn>
        </v-row>

        <div class="headline mb-2" v-if="showUserLabel">Total Users: {{ totalUsers }}</div>
        <div class="subtitle-1 mb-4" v-if="message && Object.keys(cardList).length === 0">No submissions found</div>

        <v-row>
          <v-col v-for="(cardData, index) in cardList" :key="'cd_'+index" cols="12" md="6" lg="4" sm="12">
            <v-card color="#4a4e69" dark height="160px">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title class="headline"> {{ cardData.name }}</v-card-title>
                  <v-card-text class="mt-3">
                    <h2 class="mb-5">Completes: {{ cardData.count }}</h2>
                    <h3 class="subtitle-1">{{ `${Math.round((cardData.count / totalUsers) * 100) || 0}% ` }}Completed</h3>
                  </v-card-text>
                </div>
                <v-btn @click="goToCardDetails(cardData._id)" target="_blank" class="mt-10 mr-10" fab right color="#79B5B9">
                  <v-icon dark>keyboard_arrow_right</v-icon>
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DataServiceVD from "./../services/dataServiceVD.js";
const dataServiceVD = new DataServiceVD();

export default {
  data() {
    return {
      message: false,
      showUserLabel: false,
      projectCodes: [],
      startDate: null,
      endDate: null,
      startDateMenu: false,
      endDateMenu: false,
      cardList: {},
      totalUsers: 0,
      dataToSend: {
        projectCodes: [],
      },
    };
  },

  mounted() {
    let vendorUser = this.$store.state.vendorUser;
    let dataToSend = {};

    if (vendorUser && vendorUser.roles && vendorUser.roles.includes("VENDOR") && !vendorUser.roles.includes("ADMIN")) {
      dataToSend.userId = vendorUser.userId
    }
    
    dataServiceVD.getVendorCodes(dataToSend).then((res) => {
      this.projectCodes = res.data;
    });
  },

  computed: {
    allSelected() {
      return this.dataToSend.projectCodes.length === this.projectCodes.length
    },
    someSelected() {
      return this.dataToSend.projectCodes.length > 0 && !this.allSelected;
    },
    icon() {
      if (this.allSelected) return "mdi-close-box";
      if (this.someSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  methods: {
    clearFilter() {
      this.dataToSend.startDate = undefined
      this.dataToSend.endDate = undefined
    },

    selectAll() {
      this.dataToSend.projectCodes = this.allSelected ? [] : this.projectCodes.map((r) => r.projectCode);
    },

    async fetchData() {
      let v = await this.$validator.validateAll();
      if(v) {
        this.$setLoader();
        dataServiceVD.getUserProfilingCompletes(this.dataToSend).then((res) => {
          this.cardList = res.data.total;
          this.message = true;
          this.getTotalUserCount({ projectCodes: this.dataToSend.projectCodes });
          this.$disableLoader();
        });
      }
    },

    getTotalUserCount(data) {
      dataServiceVD.getTotalUserCount(data).then((res) => {
        this.totalUsers = res.data.count;
        this.showUserLabel = true
      });
    },

    getOverallStatus() {
      this.$setLoader();
      dataServiceVD.getUserProfilingCompletes().then((res) => {
        this.getTotalUserCount();
        this.cardList = res.data.total;
        this.$disableLoader();
      });
    },

    async goToUsers() {
      let v = await this.$validator.validateAll();
      if (v) {
        this.$router.push({
          name: "VendorUPUsers",
          query: { projectCode: this.dataToSend.projectCodes },
        });
      }
    },

    goToCardDetails(profilingId) {
      this.$router.push({
        name: "VendorProfilingDetails",
        query: {
          profilingId: profilingId,
          projectCodes: JSON.stringify(this.dataToSend.projectCodes),
          startDate: this.dataToSend.startDate,
          endDate: this.dataToSend.endDate,
        },
      });
    },

    checkUserType(role) {
      let user = this.$store.state.vendorUser;
      return user && user.roles.includes(role) ? true : false;
    },
  },
};
</script>
